import React from "react";
import "./index.scss";
import oResourceBundle from "app/i18n/";
import blockedEmail from "../../../resources/assets/blockedEmail.svg";
import HandlerContext from "app/views/Context/HandlerContext";
import * as CONSTANTS from "app/AppConfig/constants";
import oResourceBundleError from "app/AppConfig/Error/";
import { sendEvents } from "core/GoogleAnalytics/";
import * as routeNames from "app/Routes/RouteNames";
import HeaderBlock from "./HeaderBlock";
import url from "url";
import * as actionTypes from "app/store/action/";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDirection } from "app/utility/common";
import * as common from "app/utility/common";
import { ToastContainer } from "react-toastify";
import { toast } from "../../../../core/components/Toaster";
import "react-toastify/dist/ReactToastify.css";
import * as appURLs from "app/AppConfig/urlConfig";

class HomeBlockedCountries extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      email: "",
      isEmailValid: false,
      isSubmitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleSubmit = async (event) => {
    const { email } = this.state;
    event.preventDefault();
    let url= appURLs.RESTRICTION_ACCESS; 
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    if (response.status === 200) {
      toast.success(oResourceBundle.saved_email, {
        position: toast.POSITION.BOTTOM_CENTER,        
      });
      this.setState({ isSubmitted: true, email: "" });
    } else if (response.status === 500) {
      toast.success(oResourceBundle.error_user_email_already_exists, {
        position: toast.POSITION.BOTTOM_CENTER,      
      });
    } else if (response.status === 400) {
      toast.success(oResourceBundle.email_invalid, {
        position: toast.POSITION.BOTTOM_CENTER,    
      });
    }
  };

  componentDidMount() {
    const url_parts = url.parse(this.props.location.search, true);
    const query = url_parts.query;
  }
  onLanguageButtonClickHandler = (oEvent) => {
    const pathname = this.props.history.location.pathname;
    const aPathnameParams = pathname.split("/");
    let newPathName = "";
    const nextLocale =
      this.props.locale === CONSTANTS.EN_CODE
        ? CONSTANTS.AR_CODE
        : CONSTANTS.EN_CODE;
    if (
      aPathnameParams.indexOf(this.props.locale) > -1 &&
      this.props.match.params.langcode
    ) {
      aPathnameParams[aPathnameParams.indexOf(this.props.locale)] = nextLocale;

      if (this.props.match.path === routeNames.VIDEO_CONTENT) {
        aPathnameParams[4] = window.sTranslatedTitle;
      } else if (this.props.match.path === routeNames.PLAYER) {
        aPathnameParams[5] = window.sTranslatedTitle;
      }
      newPathName = aPathnameParams.join("/");
    } else if (!this.props.match.params.langcode) {
      newPathName = CONSTANTS.HOME_PATH + nextLocale;
    }

    oResourceBundle.setLanguage(nextLocale);
    oResourceBundleError.setLanguage(nextLocale);

    this.props.history.push(newPathName);
    this.props.fnChangeAppDirection(nextLocale);

    sendEvents(
      CONSTANTS.CHANGE_LANGUAGE,
      nextLocale === CONSTANTS.AR_CODE
        ? CONSTANTS.AR_ACTION
        : CONSTANTS.EN_ACTION
    );
  };
  onAppBodyClicked() {
    this.state.showMenu && this.fnCloseMenu();
    this.state.showSearchInput && this.fnHideSearchInput();
    this.state.showUserMenu && this.setState({ showUserMenu: false });
  }
  onEmailChange(eve) {
    const text = eve.target.value;
    this.setState({
      email: text,
    });
    if (common.isValidEmail(text)) {
      this.setState({
        isEmailValid: true,
      });
    } else {
      this.setState({
        isEmailValid: false,
      });
    }
  }

  render() {
    const { email } = this.state;
    const oHandlers = {
      onLanguageButtonClickHandler: this.onLanguageButtonClickHandler.bind(
        this
      ),
      onAppBodyClicked: this.onAppBodyClicked.bind(this),
    };
    return (
      <>
        <div className="blocked">
          <header className="header-block">
            <div className="header_bottom">
              <div className="contentLeft">
                <div
                  className={"app-body " + getDirection(this.props.locale)}
                  dir={getDirection(this.props.locale)}
                  ref="app-body"
                  onClick={this.onAppBodyClicked.bind(this)}
                >
                  <HandlerContext.Provider value={oHandlers}>
                    <HeaderBlock
                      onLanguageButtonClickHandler={this.onLanguageButtonClickHandler.bind(
                        this
                      )}
                      locale={this.props.locale}
                    />
                  </HandlerContext.Provider>
                </div>
              </div>
        
            </div> 
          </header>
          <div>
            <div className="blocked-container-card">
              <div className="blocked-container">
                <div className="blocked_text1">
                  {oResourceBundle.blocked_text1}
                </div>
                <div
                  className={
                    "blocked_text2 app-body" + getDirection(this.props.locale)
                  }
                  dir={getDirection(this.props.locale)}
                  ref="app-body"
                >
                  {oResourceBundle.blocked_content}
                </div>
                <div
                  className={
                    "blocked_text3 app-body" + getDirection(this.props.locale)
                  }
                  dir={getDirection(this.props.locale)}
                  ref="app-body"
                >
                  {oResourceBundle.blocked_text3} <span> {oResourceBundle.blocked_text5}</span>
                </div>
                
                <div className={
                    "blocked_text3 blocked_text5 app-body" + getDirection(this.props.locale)
                  }
                  dir={getDirection(this.props.locale)}
                  ref="app-body">
                  {oResourceBundle.blocked_text5}
                </div>
              </div>
            </div>
            <form
              onSubmit={this.handleSubmit}
              className={
                "blocked_form app-body" + getDirection(this.props.locale)
              }
              dir={getDirection(this.props.locale)}
              ref="app-body"
            >
              <input
                type="email"
                className="blocked_email"
                placeholder={oResourceBundle.email}
                value={email} 
                onChange={this.onEmailChange.bind(this)}
                required
              />
               
              <ToastContainer
                autoClose={this.props.autoClose || 3000}
                hideProgressBar
              />
              <img
                  src={blockedEmail}
                  className="email_img"
                  alt="weyyak-logo"
                  onClick={this.handleSubmit}
                />
             
            </form>
            <div
              className={
                "error-message app-body" + getDirection(this.props.locale)
              }
              dir={getDirection(this.props.locale)}
              ref="app-body"
            >
              {!this.state.isEmailValid && this.state.email.length > 0 && (
                <p
                  className={
                    "error-container app-body" + getDirection(this.props.locale)
                  }
                  dir={getDirection(this.props.locale)}
                  ref="app-body"
                >
                  {oResourceBundle.email_invalid}
                </p>
              )}
               
            </div>
            <div
              className={
                "blocked_text4 app-body" + getDirection(this.props.locale)
              }
              dir={getDirection(this.props.locale)}
              ref="app-body"
            >
              {oResourceBundle.blocked_text4}
            </div>
            <div
              className={
                "blocked_text6 app-body" + getDirection(this.props.locale)
              }
              dir={getDirection(this.props.locale)}
              ref="app-body"
            >
              {oResourceBundle.blocked_text6}
            </div>
          </div>
        </div>       
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fnChangeAppDirection: (sCurrentLocale) => {
      dispatch(actionTypes.changeDirection(sCurrentLocale));
    },
    fnChangeAppLocale: (sCurrentLocale) => {
      dispatch(actionTypes.changeDirection(sCurrentLocale));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeBlockedCountries)
);
