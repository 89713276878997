/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import "resize-observer-polyfill/dist/ResizeObserver.global";
import "es6-promise/auto";
import "es6-object-assign/auto";
import includes from "array-includes";
import "es7-object-polyfill";
import "array.prototype.fill";
import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "app/store/reducer";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";
import * as routeNames from "app/Routes/RouteNames";
import AppBody from "app/views/container/AppBody";
import * as constants from "app/AppConfig/constants";
import TagManager from "react-gtm-module";
import Logger from "core/Logger";
import "./App.scss";
import BlockedCountries from "app/views/components/BlockedCountries";
import HomeBlockedCountries from "app/views/components/BlockedCountries/HomeBlocked"
import axios from "axios";
import PrivacyAndroid from "app/views/container/privacyAndroid";
import Terms from "app/views/container/TermsOfUse";
import Privacy from "app/views/container/PrivacyPolicy";
import TermsAndroid from "app/views/container/termsAndroid";
import * as appURLs from "../app/AppConfig/urlConfig";
import Cookie from "app/views/container/CookiePolicy";
import CookieAndroid from "app/views/container/CookieAndroid";
const tagManagerArgs = {
  gtmId: constants.GTM_ID,
  
};

TagManager.initialize(tagManagerArgs);

window.Array.prototype.includes = includes;
const thunkMiddleware = applyMiddleware(thunk);
const store = createStore(reducer, thunkMiddleware);

class App extends Component {
  MODULE_NAME = "App";

  constructor() {
    super();    
    this.state = {
      Blocked: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  isCountryBlocked = (currentCountryCode) => {
    let blockedCountryCodes = [
      "AL",
      "AT",
      "AD",
      "BY",
      "BE",
      "BA",
      "BG",
      "HR",
      "CZ",
      "DK",
      "EE",
      "FI",
      "FR",
      "DE",
      "GR",
      "VA",
      "HU",
      "IS",
      "IE",
      "IT",
      "LV",
      "LI",
      "LT",
      "LU",
      "MT",
      "MD",
      "MC",
      "ME",
      "NL",
      "NO",
      "MK",
      "PL",
      "PT",
      "RO",
      "RU",
      "SM",
      "RS",
      "SK",
      "SI",
      "ES",
      "SE",
      "CH",
      "UA",
      "GB",
      "AU",
      "SG",
      "CA",
      // "US",
      "UK",
      "NZ",
      "KP",
      "IR",
      "SY",
      "SD",
      "CU",
      "VE",
      "CN",
      "CF",
      "ET",
      "HK",
      "ML",
      "NI",
      "SO",
      "XK",
      "AF",
      "CD"


    ];
    for (let i = 0; i < blockedCountryCodes.length; i++) {
      if (blockedCountryCodes[i] === currentCountryCode) {
      return  this.setState({
          ...this.state,
          Blocked: true,
        });
      }
    }
  return  this.setState({
      ...this.state,
      Blocked: false,
    });
  };
  responseData = async () => {
    const response = await axios.get(appURLs.GEO_LOCATION);
    const value = response.data;
    return value;
  };
  async componentDidMount() {
    var result;
    await this.responseData().then((response) => {
      result = response;
    });

    this.isCountryBlocked(result)
  }
  onRouteChanged() {
    console.log("ROUTE CHANGED");
  }
  render() {
    Logger.log(this.MODULE_NAME, "version " + constants.BUILD_VERSION_NUMBER);
    const { Blocked, value } = this.state;
    let pathArray = (window.location.pathname.split('-') )
    let isAndroidSite =
    pathArray[pathArray.length - 2] === "android" ? true : false;
    return (
      <>
        <BrowserRouter>
          <Provider store={store}>
            <Suspense fallback={<div>Loading...</div>}>
              {Blocked ? (
                <Switch>
                  <Route exact path={routeNames.PRIVACY_POLICY} component={isAndroidSite?PrivacyAndroid:Privacy} /> 
                  <Route exact path={routeNames.TERMS_OF_USE} component={isAndroidSite?TermsAndroid:Terms} />
                  <Route exact path={routeNames.COOKIE_POLICY} component={isAndroidSite?CookieAndroid : Cookie} />
                  <Route exact path={routeNames.PLAYER} component={HomeBlockedCountries} />
                  <Route path={routeNames.VIDEO_CONTENT} component={HomeBlockedCountries} />
                  <Route path={"/"} component={BlockedCountries} />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path={routeNames.WIN}
                    component={() => (
                      <Redirect to={"/ar/" + constants.COOKING_CONTEST} />
                    )}
                  />
                  <Route path={routeNames.PLAYER} component={AppBody} />
                  <Route path={routeNames.VIDEO_CONTENT} component={AppBody} />
                  <Route path={routeNames.ROOT} component={AppBody} />
                  <Route
                    path={routeNames.VIDEO_LIST_PLANS}
                    component={AppBody}
                  />
                  <Route path={"*"} component={AppBody} />
                </Switch>
              )}
            </Suspense>
          </Provider>
        </BrowserRouter>
      </>
    );    
  }
}

export { store };
export default App;
